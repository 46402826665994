import React, { useState, useEffect, useRef } from "react";
import Footer from "../common/Footer";
import Navigation from "../common/Navigation";
import Sidebar from "../common/Sidebar";
import CommonService from "../../services/CommonService";
import Swal from "sweetalert2";

const VerifyAadhar = () => {
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef(null);
    const [epic, setEpic] = useState("");
    const [voterDetails, setVoterDetails] = useState();
    const handleCancel = () => {
        formRef.current.reset();
        setEpic("");
        setVoterDetails(null);
    }

    const rcFormSubmit = (event) =>{
        setIsLoading(true);
        var data = {
            "epic_number":epic
        }; 
        CommonService.voterIdVrification(data)
        .then((response) => {
            if(response.data.status === 200 && response.data.data.data.status === "success"){
                setVoterDetails(response.data.data);
                setIsLoading(false);
            }else{
                console.log('Error', response.data.data.data);
                Swal.fire({
                    icon: 'error',
                    title: response.data.data.data.data.code,
                    text: response.data.data.data.data.message,
                })
                setIsLoading(false);
                setVoterDetails(null);
            }
        })
        .catch((error) => {
            console.log('An error occurred:', error.response.data);
            Swal.fire({
                icon: 'error',
                title: error.response.data.data.code,
                text: error.response.data.data.message,
            })
            setIsLoading(false);
            setVoterDetails(null);
        });
    }
    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Sidebar/>
                <div className="layout-page">
                    <Navigation/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card h-100">
                                        <div className="card-body"> 
                                            <div className="row">
                                                <h5 className="card-header">Voter Id Report</h5>
                                                <hr/>
                                            </div>
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center">
                                                    <div className="sk-fold sk-primary">
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                    </div>
                                                </div>
                                            ) : ( 
                                            <form className="card-body" ref={formRef} style={{paddingTop:"0px", paddingLeft:"0px"}} data-parsley-validate="" onSubmit={ rcFormSubmit }>
                                                <div className="row">    
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="multicol-username">Voter Id Number</label>
                                                        <input type="text" defaultValue={epic}
                                                            onChange={(e) => { setEpic(e.target.value.toUpperCase()) }} required data-parsley-required="true" 
                                                            className="form-control" placeholder="Enter epic number"
                                                         />
                                                    </div>
                                                </div>
                                               <div className="pt-4">
                                                    <button type="submit" className="btn btn-primary me-sm-3 me-1">Find Status</button>
                                                    <button type="button" className="btn btn-label-secondary" onClick={handleCancel}>Reset</button>
                                                </div>
                                            </form> 
                                            )} 
                                            
                                            { voterDetails ? (
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-7">
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='menu-icon tf-icons ti ti-id me-1'></i> VOTER ID DETAILS
                                                                </p>
                                                            </ul>
                                                            <div className="card-body" style={{paddingTop:"0px"}}>
                                                                <div className="row" style={{background: '#ebe9e9'}}>
                                                                    <div className="col-md-6">
                                                                    <b>Epic Number</b> 
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.data.epic_number ? voterDetails.data.data.epic_number : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row" >
                                                                    <div className="col-md-6">
                                                                    <b>Serial Number</b> 
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.data.serial_number ? voterDetails.data.data.serial_number : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{background: '#ebe9e9'}}>
                                                                    <div className="col-md-6">
                                                                    <b>Name</b> 
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.data.name ? voterDetails.data.data.name : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                    <b>Name Regional Lang</b> 
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.data.name_in_regional_lang ? voterDetails.data.data.name_in_regional_lang : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row"  style={{background: '#ebe9e9'}} >
                                                                    <div className="col-md-6">
                                                                    <b>Father Name</b> 
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.data.father_name ? voterDetails.data.data.father_name : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                    <b>DOB</b> 
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.data.dob ? voterDetails.data.data.dob : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row"  style={{background: '#ebe9e9'}}>
                                                                    <div className="col-md-6">
                                                                    <b>Age</b> 
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.data.age ? voterDetails.data.data.age : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                    <b>Gender</b> 
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.data.gender ? voterDetails.data.data.gender : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row"  style={{background: '#ebe9e9'}}>
                                                                    <div className="col-md-6">
                                                                    <b>Address</b> 
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.data.address ? voterDetails.data.data.address : 'NA'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='menu-icon tf-icons ti ti-id me-1'></i> ADDRESS DETAILS
                                                                </p>
                                                            </ul>
                                                            <div className="card-body" style={{paddingTop:"0px"}}>
                                                                <div className="row" style={{background: '#ebe9e9'}}>
                                                                    <div className="col-md-6">
                                                                    <b>Assembly Constituency Number</b> 
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.data.assembly_constituency_number ? voterDetails.data.data.assembly_constituency_number : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                    <b>Assembly Constituency</b> 
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.data.assembly_constituency ? voterDetails.data.data.assembly_constituency : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row"  style={{background: '#ebe9e9'}}>
                                                                    <div className="col-md-6">
                                                                    <b>Part Number</b> 
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.data.part_number ? voterDetails.data.data.part_number : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                    <b>Address</b> 
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.data.split_address.address_line ? voterDetails.data.data.split_address.address_line : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row"  style={{background: '#ebe9e9'}}>
                                                                    <div className="col-md-6">
                                                                    <b>City</b> 
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.data.split_address.city[0] ? voterDetails.data.data.split_address.city[0] : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-6" >
                                                                        <b>Pincode</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.data.split_address.pincode ? voterDetails.data.data.split_address.pincode : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row"   style={{background: '#ebe9e9'}}>
                                                                    <div className="col-md-6">
                                                                    <b>State</b> 
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.data.split_address.state ? voterDetails.data.data.split_address.state : 'NA'}
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="row">
                                                                    <div className="col-md-6" >
                                                                        <b>Country</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.data.split_address.country[2] ? voterDetails.data.data.split_address.country[2] : 'NA'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-5">
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='ti-xs ti ti-link me-1'></i> PROFILE DETAILS
                                                                </p>
                                                            </ul>
                                                            <div className="card-body" style={{paddingTop:"0px"}}>
                                                                <div className="row" style={{background: '#ebe9e9'}}>
                                                                    <div className="col-md-6">
                                                                    <b>Photo</b> 
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        <img src={voterDetails.data.data.photo} alt="Voter Id Photo" style={{width:"80px"}}/>
                                                                    </div>
                                                                </div>
                                                                <div className="row" >
                                                                    <div className="col-md-6" >
                                                                        <b>Message</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.data.message ? voterDetails.data.data.message : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{background: '#ebe9e9'}}>
                                                                    <div className="col-md-6" >
                                                                        <b>Status</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{ textAlign: "right", color: voterDetails.data.data.status === 'VALID' ? 'green' : 'red' }}>
                                                                        {voterDetails.data.data.status ? voterDetails.data.data.status : 'NA'}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                    <i className='ti-xs ti ti-link me-1'></i> RELATION DETAILS
                                                                </p>
                                                            </ul>
                                                            <div className="card-body" style={{paddingTop:"0px"}}>
                                                                <div className="row"  style={{background: '#ebe9e9'}}>
                                                                    <div className="col-md-6" >
                                                                        <b>Relation Name</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.data.relation_name ? voterDetails.data.data.relation_name : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row" >
                                                                    <div className="col-md-6" >
                                                                        <b>Relation In Regional Lang</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.data.relation_name_in_regional_lang ? voterDetails.data.data.relation_name_in_regional_lang : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row"  style={{background: '#ebe9e9'}}>
                                                                    <div className="col-md-6" >
                                                                        <b>Relation Type</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.data.relation_type ? voterDetails.data.data.relation_type : 'NA'}
                                                                    </div>
                                                                </div>
                                                                <div className="row" >
                                                                    <div className="col-md-6" >
                                                                        <b>Relation Name</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.data.relation_name ? voterDetails.data.data.relation_name : 'NA'}
                                                                    </div>
                                                                </div>
                                                            
                                                            </div>
                                                            <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                                <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-clock-12"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 12a9 9 0 0 0 9 9m9 -9a9 9 0 1 0 -18 0" /><path d="M12 7v5l.5 .5" /><path d="M18 15h2a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-1a1 1 0 0 0 -1 1v1a1 1 0 0 0 1 1h2" /><path d="M15 21v-6" /></svg> &nbsp;  API HEALTH CHECK
                                                                </p>
                                                            </ul>
                                                            <div className="card-body" style={{paddingTop:"0px"}}>
                                                                <div className="row" style={{background: '#ebe9e9'}}>
                                                                    <div className="col-md-6">
                                                                    <b>Request Time</b> 
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.requestTimestamp}
                                                                    </div>
                                                                </div>
                                                                <div className="row" >
                                                                    <div className="col-md-6" >
                                                                        <b>Response Time</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.responseTimestamp}
                                                                    </div>
                                                                </div>
                                                                <div className="row" style={{background: '#ebe9e9'}}>
                                                                    <div className="col-md-6" >
                                                                        <b>Latency(sec)</b>
                                                                    </div>
                                                                    <div className="col-md-6" style={{textAlign:"right"}}>
                                                                        {voterDetails.data.totalTime}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : ( '' )}   
                                            
                                        </div>
                                    </div>    
                                </div>
                             </div> 
                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
            <div className="drag-target"></div>
        </div>    
    </>
    )
}   

export default VerifyAadhar;