import React, { useState, useContext, useCallback } from "react";
import { Link, useNavigate } from 'react-router-dom'
import  axios  from 'axios';
import Swal from "sweetalert2";
import 'parsleyjs';
import setting from "../setting";
import GlobalContext from "../contexts/GlobelContext";
import Cookies from "universal-cookie";

export default function login(){
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const nav = useNavigate();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isVisible, setVisible] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [userEmail, setuserEmail] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [userPassword, setuserPassword] = useState('');
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [isLoading, setIsLoading] = useState(false);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { isUserAuth, setIsUserAuth } = useContext(GlobalContext);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const saveTokenInCookies = useCallback((selectedToken, menuItem) => {
     
        const cookies = new Cookies();
        cookies.set("auth_token", selectedToken);
        cookies.set("menuItem", menuItem);
      }, []);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const signInUser = useCallback(async (event) =>{
        
        setIsLoading(true);
            var data = {
                "email":userEmail,
                "password":userPassword
            
        };

        let headers= { 
            'Content-Type': 'application/json'
        }
    
        axios.post(`${setting.APP_DEV_URL}sign-in`, data,  { headers: headers })
        .then(response =>{
          
            localStorage.clear();
            if(response.data.status === 200){
                localStorage.setItem("id", response.data.data.loggedInDetails.id);
                localStorage.setItem("first_name", response.data.data.loggedInDetails.first_name);
                localStorage.setItem("last_name", response.data.data.loggedInDetails.last_name);
                localStorage.setItem("email", response.data.data.loggedInDetails.email);
                localStorage.setItem("profile_pic", response.data.data.loggedInDetails.profile_pic);
                localStorage.setItem("jwt_token", response.data.data.loggedInDetails.jwt_token);
                var  menuItem = response.data.data.checkSubscription;
                localStorage.setItem("menuItem", JSON.stringify(menuItem));
                saveTokenInCookies(response.data.data.loggedInDetails.jwt_token, menuItem);
                localStorage.setItem("loggedInUser", true);
                setIsUserAuth(true);
                setIsLoading(false);
                nav("/dashboard");
            }else{
                localStorage.clear();
                Swal.fire({
                    icon: 'error',
                    title: response.data.status,
                    text: response.data.message,
                })
                setIsLoading(false)
            }
            
        })
        .catch(error => {
            localStorage.clear();
            //console.log('An error occurred:', error.response.data);
            Swal.fire({
                icon: 'error',
                title: error.response.data.status,
                text: error.response.data.message,
            })
            setIsLoading(false)
        });
    }, [userEmail, userPassword, setIsUserAuth, nav])

    const toggle = () => {
        setVisible(!isVisible);
      };

    return (
        <>
        <style>
           {` 
                #parsley-id-5 {
                    margin: 41px 0px;
                }
          `}
        </style>
            <div className="authentication-wrapper authentication-cover authentication-bg">
              <div className="authentication-inner row">
                <div className="d-none d-lg-flex col-lg-7 p-0">
                  <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
                    <img src="../../assets/img/illustrations/auth-login-illustration-light.png" alt="auth-login-cover" className="img-fluid my-5 auth-illustration" data-app-light-img="illustrations/auth-login-illustration-light.png" data-app-dark-img="illustrations/auth-login-illustration-dark.png"/>

                    <img src="../../assets/img/illustrations/bg-shape-image-light.png" alt="auth-login-cover" className="platform-bg" data-app-light-img="illustrations/bg-shape-image-light.png" data-app-dark-img="illustrations/bg-shape-image-dark.png"/>
                  </div>
                </div>
                <div className="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
                  <div className="w-px-400 mx-auto">
                    <div className="app-brand mb-4">
                         <img src={process.env.PUBLIC_URL + '/logo.jpg'} style={{width:'100px'}}/>
                    </div>
                    <h3 className="mb-1">Welcome to Clients! 👋</h3>
                    <p className="mb-4">Please sign-in to your account and start the adventure</p>
                    {isLoading ? (
                        <div className="d-flex justify-content-center">
                            <div className="sk-fold sk-primary">
                                <div className="sk-fold-cube"></div>
                                <div className="sk-fold-cube"></div>
                                <div className="sk-fold-cube"></div>
                                <div className="sk-fold-cube"></div>
                            </div>
                        </div>
                    ) : (
                    <form  id="formAuthentication" className="mb-3" data-parsley-validate="" onSubmit={ signInUser } >
                        <div className="row">
                            <div className="mb-3 form-password-toggle">
                                <label htmlFor="email" className="form-label">Email</label>
                                <div className="input-group input-group-merge">
                                    <input type="text" className="form-control" id="email" name="email" placeholder="Enter your email" defaultValue={userEmail}
                                    onChange={(e) => { setuserEmail(e.target.value) }} required data-parsley-required="true"  />
                                    <span className="input-group-text" id="inputGroupPrepend">@</span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mb-3 form-password-toggle">
                                <label className="form-label">Password</label>
                                <div className="input-group input-group-merge">
                                <input type={!isVisible ? "password" : "text"} id="password" className="form-control" name="password" placeholder="Please enter password!" aria-describedby="password" required data-parsley-required="true"
                                defaultValue={userPassword}
                                onChange={(e) => { setuserPassword(e.target.value) }}
                                />
                                  <span className="input-group-text cursor-pointer" onClick={toggle}>
                                      {isVisible ? <i className="ti ti-eye"></i>: <i className="ti ti-eye-off"></i> }
                                  </span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="mb-3">
                                <div className="d-flex justify-content-between" style={{float:"right"}}>
                                <Link to="/forgot-password" style={{textDecoration:"none"}}>
                                    <small>Forgot Password?</small>
                                </Link>
                                </div>
                            </div>  
                        </div>    
                        <button  type="submit" className="btn btn-primary d-grid w-100">
                            Sign in
                        </button>
                    </form>
                    )}    
                    {/* <p className="text-center">
                      <span>New on our platform?</span>
                      <Link to="/sign-up">
                        <span>Create an account</span>
                      </Link>
                    </p> */}

                  </div>
                </div>
              </div>
            </div>
        </>
    );
}