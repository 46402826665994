import React, { useState, useEffect, useRef } from "react";
import Footer from "../common/Footer";
import Navigation from "../common/Navigation";
import Sidebar from "../common/Sidebar";
import CommonService from "../../services/CommonService";
import Swal from "sweetalert2";

const VerifyAadhar = () => {
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef(null);
    const [otp, setOtp] = useState("");
    const [aadharDetails, setAadharDetails] = useState();
    const handleCancel = () => {
        formRef.current.reset();
        setOtp("");
        setAadharDetails(null);
    }

    const rcFormSubmit = (event) =>{
        setIsLoading(true);
        var data = {
            "otp":otp,
            "ref_id":localStorage.getItem('ref_id'),
            "urn":localStorage.getItem('urn')
        };
        CommonService.aadharOTPVrification(data)
        .then((response) => {
            if(response.data.status === 200){
                console.log(response);
                setAadharDetails(response.data.data);
                setIsLoading(false);
            }else{
                Swal.fire({
                    icon: 'error',
                    title: response.data.status,
                    text: response.data.data.message,
                })
                setIsLoading(false);
                setAadharDetails(null);
            }
        })
        .catch((error) => {
            console.log('An error occurred:', error.response.data);
            Swal.fire({
                icon: 'error',
                title: error.response.data.data.code,
                text: error.response.data.data.message,
            })
            setIsLoading(false);
            setAadharDetails(null);
        });
    }
    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Sidebar/>
                <div className="layout-page">
                    <Navigation/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card h-100">
                                        <div className="card-body"> 
                                            <div className="row">
                                                <h5 className="card-header">Aadhar Report</h5>
                                                <hr/>
                                            </div>
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center">
                                                    <div className="sk-fold sk-primary">
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                    </div>
                                                </div>
                                            ) : ( 
                                            <form className="card-body" ref={formRef} style={{paddingTop:"0px", paddingLeft:"0px"}} data-parsley-validate="" onSubmit={ rcFormSubmit }>
                                                <div className="row">    
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="multicol-username">Otp</label>
                                                        <input type="text" defaultValue={otp}
                                                            onChange={(e) => { setOtp(e.target.value.toUpperCase()) }} required data-parsley-required="true" 
                                                            className="form-control" placeholder="Enter OTP"
                                                         />
                                                    </div>
                                                </div>
                                               <div className="pt-4">
                                                    <button type="submit" className="btn btn-primary me-sm-3 me-1">Find Status</button>
                                                    <button type="button" className="btn btn-label-secondary" onClick={handleCancel}>Reset</button>
                                                </div>
                                            </form> 
                                            )} 
                                            
                                            { aadharDetails ? (
                                                <>
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='menu-icon tf-icons ti ti-id me-1'></i> AADHAR DETAILS
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                   <b>Name</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {aadharDetails.data.data.name ? aadharDetails.data.data.name : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                   <b>S/o</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {aadharDetails.data.data.care_of ? aadharDetails.data.data.care_of : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}} >
                                                                <div className="col-md-6">
                                                                   <b>Email</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {aadharDetails.data.data.email ? aadharDetails.data.data.email : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                   <b>Gender</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {aadharDetails.data.data.gender ? aadharDetails.data.data.gender : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                   <b>DOB</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {aadharDetails.data.data.dob ? aadharDetails.data.data.dob : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                   <b>Address</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {aadharDetails.data.data.address ? aadharDetails.data.data.address : 'NA'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='menu-icon tf-icons ti ti-id me-1'></i> ADDRESS DETAILS
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                   <b>House</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {aadharDetails.data.data.split_address.house ? aadharDetails.data.data.split_address.house : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" >
                                                                <div className="col-md-6" >
                                                                    <b>Landmark</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {aadharDetails.data.data.split_address.landmark ? aadharDetails.data.data.split_address.landmark : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>Street</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {aadharDetails.data.data.split_address.street ? aadharDetails.data.data.split_address.street : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <b>Post</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {aadharDetails.data.data.split_address.po ? aadharDetails.data.data.split_address.po : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                   <b>Subdist</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {aadharDetails.data.data.split_address.subdist ? aadharDetails.data.data.split_address.subdist : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" >
                                                                <div className="col-md-6" >
                                                                    <b>Dist</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {aadharDetails.data.data.split_address.dist ? aadharDetails.data.data.split_address.dist : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>Pincode</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {aadharDetails.data.data.split_address.pincode ? aadharDetails.data.data.split_address.pincode : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                   <b>State</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {aadharDetails.data.data.split_address.state ? aadharDetails.data.data.split_address.state : 'NA'}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>Country</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {aadharDetails.data.data.split_address.country ? aadharDetails.data.data.split_address.country : 'NA'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='ti-xs ti ti-link me-1'></i> PROFILE DETAILS
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                   <b>Photo</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    <img src={aadharDetails.data.data.photo_link} alt="Aadhar Photo" style={{width:"80px"}}/>
                                                                </div>
                                                            </div>
                                                            <div className="row" >
                                                                <div className="col-md-6" >
                                                                    <b>Message</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {aadharDetails.data.data.message ? aadharDetails.data.data.message : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>Status</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right", color: aadharDetails.data.data.status === 'VALID' ? 'green' : 'red' }}>
                                                                    {aadharDetails.data.data.status ? aadharDetails.data.data.status : 'NA'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-clock-12"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 12a9 9 0 0 0 9 9m9 -9a9 9 0 1 0 -18 0" /><path d="M12 7v5l.5 .5" /><path d="M18 15h2a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-1a1 1 0 0 0 -1 1v1a1 1 0 0 0 1 1h2" /><path d="M15 21v-6" /></svg> &nbsp;  API HEALTH CHECK
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                   <b>Request Time</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {aadharDetails.data.requestTimestamp}
                                                                </div>
                                                            </div>
                                                            <div className="row" >
                                                                <div className="col-md-6" >
                                                                    <b>Response Time</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {aadharDetails.data.responseTimestamp}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>Latency(sec)</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {aadharDetails.data.totalTime}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                             </>
                                            ) : ( '' )}   
                                            
                                        </div>
                                    </div>    
                                </div>
                             </div> 
                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
            <div className="drag-target"></div>
        </div>    
    </>
    )
}   

export default VerifyAadhar;