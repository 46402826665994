import React, { useState, useEffect,useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../common/Footer";
import Navigation from "../common/Navigation";
import Sidebar from "../common/Sidebar";
import CommonService from "../../services/CommonService";
import Swal from "sweetalert2";

const Pan = () => {
    const nav = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef(null);
    const [aadhar, setAadhar] = useState("");
    const [rcDetails, setRcDetails] = useState();
    const handleCancel = () => {
        // Reset the form
        formRef.current.reset();
        setAadhar("");
        setRcDetails(null);
    }

    const rcFormSubmit = (event) =>{
        setIsLoading(true);
        var data = {
            "aadhaar_no":aadhar
        };
        CommonService.aadharOtp(data)
        .then((response) => {
           
            if(response.data.status === 200){
                localStorage.setItem("urn", response.data.data.urn);
                localStorage.setItem("ref_id", response.data.data.checkotpDetails.ref_id);
                Swal.fire({
                    icon: 'success',
                    title: response.data.data.checkotpDetails.status,
                    text: 'OTP sent successfully on registered mobile number ',
                })
                setIsLoading(false);
                nav("/aio-aadhar-verify");
            }else{
                
                Swal.fire({
                    icon: 'error',
                    title: response.data.status,
                    text: response.data.message,
                })
                setIsLoading(false);
                setRcDetails(null);
            }
        })
        .catch((error) => {
            console.log('An error occurred:', error.response.data.data);
            if(error.response.data.status === 510){
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.status,
                    text: error.response.data.data,
                })
                setIsLoading(false);
                setRcDetails(null);
            }else{
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.status,
                    text: error.response.data.message,
                })
                setIsLoading(false);
                setRcDetails(null);
            }
        });
    }
    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Sidebar/>
                <div className="layout-page">
                    <Navigation/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card h-100">
                                        <div className="card-body"> 
                                            <div className="row">
                                                <h5 className="card-header">Aio Aadhar Report</h5>
                                                <hr/>
                                            </div>
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center">
                                                    <div className="sk-fold sk-primary">
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                    </div>
                                                </div>
                                            ) : ( 
                                            <form className="card-body" ref={formRef} style={{paddingTop:"0px", paddingLeft:"0px"}} data-parsley-validate="" onSubmit={ rcFormSubmit }>
                                                <div className="row">    
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="multicol-username">Aadhar Number</label>
                                                        <input type="text" defaultValue={aadhar}
                                                            onChange={(e) => { setAadhar(e.target.value.toUpperCase()) }} required data-parsley-required="true" 
                                                            className="form-control" placeholder="Enter aadhar number"
                                                         />
                                                    </div>
                                                </div>
                                               <div className="pt-4">
                                                    <button type="submit" className="btn btn-primary me-sm-3 me-1">Find Status</button>
                                                    <button type="button" className="btn btn-label-secondary" onClick={handleCancel}>Reset</button>
                                                </div>
                                            </form> 
                                            )} 
                                        </div>
                                    </div>    
                                </div>
                             </div> 
                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
            <div className="drag-target"></div>
        </div>    
    </>
    )
}   

export default Pan;