import React, { useState, useEffect,useRef } from "react";
import Footer from "../common/Footer";
import Navigation from "../common/Navigation";
import Sidebar from "../common/Sidebar";
import CommonService from "../../services/CommonService";
import Swal from "sweetalert2";

const Driver = () => {
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef(null);
    const [dl, setDL] = useState("");
    const [dob, setDob] = useState("");
    const [dlDetails, setDLDetails] = useState();
    const handleCancel = () => {
        // Reset the form
        formRef.current.reset();
        setDL("");
        setDLDetails(null);
    }

    const panFormSubmit = (event) =>{
        setIsLoading(true);
        var data = {
            "dl_number":dl,
            "dob":dob
        };
        CommonService.dlSearchNormal(data)
        .then((response) => {
            if(response.data.status === 200 && response.data.data.data.status === "success"){
                setDLDetails(response.data.data);
                setIsLoading(false);
            }else{
                console.log('Error', response.data.data.data);
                Swal.fire({
                    icon: 'error',
                    title: response.data.data.data.data.code,
                    text: response.data.data.data.data.message,
                })
                setIsLoading(false);
                setDLDetails(null);
            }
        })
        .catch((error) => {
            console.log('An error occurred:', error.response.data.data);
            if(error.response.data.status === 510){
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.status,
                    text: error.response.data.data,
                })
                setIsLoading(false);
                setDLDetails(null);
            }else if(error.response.data.data.status === 'INVALID'){
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.data.status,
                    text: 'Driving licence not valid. please try again',
                })
                setIsLoading(false);
                setDLDetails(null);
            }
            else{
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.data.type,
                    text: error.response.data.data.message,
                })
                setIsLoading(false);
                setDLDetails(null);
            }
        });
    }

    
    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Sidebar/>
                <div className="layout-page">
                    <Navigation/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card h-100">
                                        <div className="card-body"> 
                                            <div className="row">
                                                <h5 className="card-header">Driving Licence Report</h5>
                                                <hr/>
                                            </div>
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center">
                                                    <div className="sk-fold sk-primary">
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                    </div>
                                                </div>
                                            ) : ( 
                                            <form className="card-body" ref={formRef} style={{paddingTop:"0px", paddingLeft:"0px"}} data-parsley-validate="" onSubmit={ panFormSubmit }>
                                                <div className="row">    
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="multicol-username">Driving Licence Number</label>
                                                        <input type="text" defaultValue={dl}
                                                            onChange={(e) => { setDL(e.target.value) }} required data-parsley-required="true" 
                                                            className="form-control" placeholder="Enter Driving Licence number"
                                                            maxLength={20} 
                                                            />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="multicol-username">Date Of Birth.</label>
                                                        <input type="date" defaultValue={dob}
                                                            onChange={(e) => { setDob(e.target.value) }} required data-parsley-required="true" 
                                                            className="form-control" placeholder="Enter dob"
                                                           
                                                            />
                                                    </div>
                                                    
                                                </div>
                                               <div className="pt-4">
                                                    <button type="submit" className="btn btn-primary me-sm-3 me-1">Find Status</button>
                                                    <button type="button" className="btn btn-label-secondary" onClick={handleCancel}>Reset</button>
                                                </div>
                                            </form> 
                                            )} 
                                            
                                            { dlDetails ? (
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='menu-icon tf-icons ti ti-id me-1'></i> Driving Licence Details
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                   <b>DL Number</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {dlDetails.data.data.dl_number ? dlDetails.data.data.dl_number : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Name</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {dlDetails.data.data.details_of_driving_licence.name ? dlDetails.data.data.details_of_driving_licence.name : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Date of Birth</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {dlDetails.data.data.dob ? dlDetails.data.data.dob  : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Father/Husband Name</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {dlDetails.data.data.details_of_driving_licence.father_or_husband_name ? dlDetails.data.data.details_of_driving_licence.father_or_husband_name  : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                   <b>Date of Issue</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {dlDetails.data.data.details_of_driving_licence.date_of_issue ? dlDetails.data.data.details_of_driving_licence.date_of_issue : 'NA'}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Address</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {dlDetails.data.data.details_of_driving_licence.address ? dlDetails.data.data.details_of_driving_licence.address : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>District</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                    {dlDetails.data.data.details_of_driving_licence.split_address.district[0] ? dlDetails.data.data.details_of_driving_licence.split_address.district[0] : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" >
                                                                <div className="col-md-6">
                                                                    <b>City</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {dlDetails.data.data.details_of_driving_licence.split_address.city[0] ? dlDetails.data.data.details_of_driving_licence.split_address.city[0] : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Pincode</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                    {dlDetails.data.data.details_of_driving_licence.split_address.pincode ? dlDetails.data.data.details_of_driving_licence.split_address.pincode : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>State</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                    {dlDetails.data.data.details_of_driving_licence.split_address.state[0] ? dlDetails.data.data.details_of_driving_licence.split_address.state[0] : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Country</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                    {dlDetails.data.data.details_of_driving_licence.split_address.country[1] ? dlDetails.data.data.details_of_driving_licence.split_address.country[1] : 'NA'}
                                                                </div>
                                                            </div>
                                                           
                                                        </div>
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='ti-xs ti ti-link me-1'></i>Driving Licence  Validity
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Hazardous Valid Till</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                    {dlDetails.data.data.dl_validity.hazardous_valid_till ? dlDetails.data.data.dl_validity.hazardous_valid_till : 'NA'}                                                               </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Hill Valid Till</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                    {dlDetails.data.data.dl_validity.hill_valid_till ? dlDetails.data.data.dl_validity.hill_valid_till : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                <b>Non Transport From</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {dlDetails.data.data.dl_validity.non_transport.from ? dlDetails.data.data.dl_validity.non_transport.from : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                <b>Non Transport To</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {dlDetails.data.data.dl_validity.non_transport.to ? dlDetails.data.data.dl_validity.non_transport.to : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                <b>Transport From</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {dlDetails.data.data.dl_validity.transport.from ? dlDetails.data.data.dl_validity.transport.from : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                <b>Transport To</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {dlDetails.data.data.dl_validity.transport.to ? dlDetails.data.data.dl_validity.transport.to : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Status</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right", color: dlDetails.data.data.status === 'VALID' ? 'green' : 'red' }}>
                                                                    {dlDetails.data.data.status ? dlDetails.data.data.status : 'NA'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-clock-12"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 12a9 9 0 0 0 9 9m9 -9a9 9 0 1 0 -18 0" /><path d="M12 7v5l.5 .5" /><path d="M18 15h2a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-1a1 1 0 0 0 -1 1v1a1 1 0 0 0 1 1h2" /><path d="M15 21v-6" /></svg> &nbsp;  API HEALTH CHECK
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                <b> Request Time</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {dlDetails.data.requestTimestamp}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Response Time</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {dlDetails.data.responseTimestamp}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                <b>Latency(sec)</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {dlDetails.data.totalTime}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='ti-xs ti ti-link me-1'></i> Profile Photo
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                <b> Photo</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    <img src={dlDetails.data.data.details_of_driving_licence.photo} style={{ width: "80px" }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : ( '' )}   
                                            
                                        </div>
                                    </div>    
                                </div>
                             </div> 
                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
            <div className="drag-target"></div>
        </div>    
    </>
    )
}   

export default Driver;