// AuthRoutes.js
import React, { useEffect, useContext } from "react";
import { Outlet, Navigate } from "react-router-dom";
import GlobalContext from "../contexts/GlobelContext"; // Correct import

const AuthRoutes = () => {
  const { isUserAuth, setIsUserAuth } = useContext(GlobalContext);

  useEffect(() => {
    if (localStorage.getItem("loggedInUser") === "true") {
      setIsUserAuth(true);
    } else {
      setIsUserAuth(false);
    }
  }, [setIsUserAuth]);

  return isUserAuth ? (
    <>
      <Outlet />
    </>
  ) : (
    <Navigate to={"/login"} />
  );
};

export default AuthRoutes;
