import React, { useState, useRef } from "react";
import { useNavigate } from 'react-router-dom'
import Footer from "../common/Footer";
import Navigation from "../common/Navigation";
import Sidebar from "../common/Sidebar";
import CommonService from "../../services/CommonService";
import Swal from "sweetalert2";
 

const Changepassword = () => {
    const nav = useNavigate();
    const [isVisible, setVisible] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef(null);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const handleCancel = () => {
        // Reset the form
        formRef.current.reset();
        setNewPassword("");
        setConfirmPassword("");
    }

    const chnagePasswordFormSubmit = (event) => {
        event.preventDefault(); // Prevent default form submission behavior
    
        setIsLoading(true);
        if (newPassword !== confirmPassword) {
            Swal.fire({
                icon: 'error',
                title: 'Password mismatch',
                text: 'Password and confirm password not matched',
            })
            setIsLoading(false);
            return;
        }

        const lengthRegex = /^.{8,16}$/;
        const uppercaseRegex = /^(?=.*[A-Z]).*$/;
        const numericRegex = /^(?=.*\d).*$/;
        const specialCharRegex = /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.\/?]).*$/;
    
        if (
            !lengthRegex.test(newPassword) ||
            !uppercaseRegex.test(newPassword) ||
            !numericRegex.test(newPassword) ||
            !specialCharRegex.test(newPassword)
        ) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Password',
                text: 'Password must meet the specified conditions.',
            });
            setIsLoading(false);
            return;
        }
    
        var data = {
            "id": localStorage.getItem("id"),
            "newPassword": newPassword,
        };
    
        CommonService.passwordChnage(data)
            .then((response) => {
            if (response.data.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: response.data.status,
                    text: response.data.message,
                }).then((result) => {
                    if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                        nav("/dashboard");
                    }
                });
                setIsLoading(false);
            } else {

                Swal.fire({
                    icon: 'error',
                    title: response.data.status,
                    text: response.data.message,
                })
                setIsLoading(false);
            }
        })
        .catch((error) => {
            console.log('An error occurred:', error);
            Swal.fire({
                icon: 'error',
                title: error.response.data.status,
                text: error.response.data.message,
            })
            setIsLoading(false);
        });       
    }
    

    const toggle = () => {
        setVisible(!isVisible);
    };

    const toggleConfirm = () => {
        setConfirmVisible(!confirmVisible);
    };

    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Sidebar/>
                <div className="layout-page">
                    <Navigation/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card h-100">
                                        <div className="card-body"> 
                                            <div className="row">
                                                <h5 className="card-header">Change Password</h5>
                                                <hr/>
                                            </div>
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center">
                                                    <div className="sk-fold sk-primary">
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                    </div>
                                                </div>
                                            ) : ( 
                                            <form className="card-body" ref={formRef} style={{paddingTop:"0px"}} data-parsley-validate="" onSubmit={ chnagePasswordFormSubmit }>
                                                <div className="row">    
                                                    <div className="col-md-6">
                                                        <label className="form-label" htmlFor="multicol-username">New Password</label>
                                                        <div className="input-group input-group-merge">
                                                            <input type={!isVisible ? "password" : "text"} 
                                                            id="password" className="form-control" 
                                                            name="password" placeholder="Please enter password!" aria-describedby="password" required data-parsley-required="true"
                                                            defaultValue={newPassword}
                                                            onChange={(e) => { setNewPassword(e.target.value) }}
                                                            />
                                                            <span className="input-group-text cursor-pointer" onClick={toggle}>
                                                                {isVisible ? <i className="ti ti-eye"></i>: <i className="ti ti-eye-off"></i> }
                                                            </span>
                                                        </div>
                                                    </div>
                                                    
                                                </div><br/>
                                                <div className="row">    
                                                    <div className="col-md-6">
                                                        <label className="form-label" htmlFor="multicol-username">Confirm Password</label>
                                                        <div className="input-group input-group-merge">
                                                            <input type={!confirmVisible ? "password" : "text"} id="password" className="form-control" name="password" placeholder="Please enter confirm password!" aria-describedby="password" required data-parsley-required="true"
                                                            defaultValue={confirmPassword}
                                                            onChange={(e) => { setConfirmPassword(e.target.value) }}
                                                            />
                                                            <span className="input-group-text cursor-pointer" onClick={toggleConfirm}>
                                                                {confirmVisible ? <i className="ti ti-eye"></i>: <i className="ti ti-eye-off"></i> }
                                                            </span>
                                                        </div>    
                                                    </div>
                                                    <div className="col-md-6">
                                                        <h6>Note:-</h6>
                                                        <p>1.Password length should be minimum 8 to 16.</p>
                                                        <p>2.First letter should be upper case.</p>
                                                        <p>3.One numeric value at least.</p>
                                                        <p>4.One special character at least.</p>
                                                    </div>
                                                </div><br/>
                                               <div className="pt-4">
                                                    <button type="submit" className="btn btn-primary me-sm-3 me-1">Change Password</button>
                                                    <button type="button" className="btn btn-label-secondary" onClick={handleCancel}>Reset</button>
                                                </div>
                                            </form> 
                                            )} 
                                            
                                        </div>
                                    </div>    
                                </div>
                             </div> <br/>
                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
            <div className="drag-target"></div>
        </div>    
    </>
    )
}   

export default Changepassword;