import React from "react";
import { Link, useNavigate } from "react-router-dom";

const Navigation = () => {
    const nav = useNavigate();
    let logoutTimer;

        const startLogoutTimer = () => {
        const logoutTime = 3 * 60 * 60 * 1000; // 1 hour
            logoutTimer = setTimeout(logout, logoutTime);
        };

        const resetLogoutTimer = () => {
            clearTimeout(logoutTimer);
            startLogoutTimer();
        };
        const logout = () => {
            localStorage.clear();
            document.cookie.split(";").forEach((cookie) => {
                const eqPos = cookie.indexOf("=");
                const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
            });
            nav("/login");
        };
        const user_name = localStorage.getItem('first_name')+' '+localStorage.getItem('last_name');

        document.addEventListener("mousemove", resetLogoutTimer);
        document.addEventListener("keypress", resetLogoutTimer);
        startLogoutTimer();
    return (
        <>
            <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
                <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0   d-xl-none ">
                    <Link className="nav-item nav-link px-0 me-xl-4" to="#">
                        <i className="ti ti-menu-2 ti-sm"></i>
                    </Link>
                    </div>
                    <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
                    <div className="navbar-nav align-items-center">
                        {/* <div className="nav-item navbar-search-wrapper mb-0">
                            <Link className="nav-item nav-link search-toggler d-flex align-items-center px-0" to="#">
                                <i className="ti ti-search ti-md me-2"></i>
                                <span className="d-none d-md-inline-block text-muted">Search (Ctrl+/)</span>
                            </Link>
                        </div> */}
                    </div>
                    <ul className="navbar-nav flex-row align-items-center ms-auto">
                         {/* <li className="nav-item dropdown-style-switcher dropdown me-2 me-xl-0">
                            <Link className="nav-link dropdown-toggle hide-arrow" to="#;" data-bs-toggle="dropdown">
                                <i className='ti ti-md'></i>
                            </Link>
                            <ul className="dropdown-menu dropdown-menu-end dropdown-styles">
                                <li>
                                    <Link className="dropdown-item" to="#" data-theme="light">
                                        <span className="align-middle"><i className='ti ti-sun me-2'></i>Light</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="#" data-theme="dark">
                                        <span className="align-middle"><i className="ti ti-moon me-2"></i>Dark</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="#" data-theme="system">
                                        <span className="align-middle"><i className="ti ti-device-desktop me-2"></i>System</span>
                                    </Link>
                                </li>
                            </ul>
                        </li> */}
                        {/* <li className="nav-item dropdown-shortcuts navbar-dropdown dropdown me-2 me-xl-0">
                            <Link className="nav-link dropdown-toggle hide-arrow" to="#;" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                            <i className='ti ti-layout-grid-add ti-md'></i>
                            </Link>
                            <div className="dropdown-menu dropdown-menu-end py-0">
                                <div className="dropdown-menu-header border-bottom">
                                <div className="dropdown-header d-flex align-items-center py-3">
                                    <h5 className="text-body mb-0 me-auto">Shortcuts</h5>
                                    <Link to="#" className="dropdown-shortcuts-add text-body" data-bs-toggle="tooltip" data-bs-placement="top" title="Add shortcuts"><i className="ti ti-sm ti-apps"></i></Link>
                                </div>
                                </div>
                                <div className="dropdown-shortcuts-list scrollable-container">
                                <div className="row row-bordered overflow-visible g-0">
                                    <div className="dropdown-shortcuts-item col">
                                        <span className="dropdown-shortcuts-icon rounded-circle mb-2">
                                        <i className="ti ti-calendar fs-4"></i>
                                        </span>
                                        <Link to="#" className="stretched-link">Calendar</Link>
                                        <small className="text-muted mb-0">Appointments</small>
                                    </div>
                                    <div className="dropdown-shortcuts-item col">
                                        <span className="dropdown-shortcuts-icon rounded-circle mb-2">
                                        <i className="ti ti-file-invoice fs-4"></i>
                                        </span>
                                        <Link to="#" className="stretched-link">Invoice App</Link>
                                        <small className="text-muted mb-0">Manage Accounts</small>
                                    </div>
                                </div>
                                <div className="row row-bordered overflow-visible g-0">
                                    <div className="dropdown-shortcuts-item col">
                                        <span className="dropdown-shortcuts-icon rounded-circle mb-2">
                                        <i className="ti ti-users fs-4"></i>
                                        </span>
                                        <Link to="#" className="stretched-link">User App</Link>
                                        <small className="text-muted mb-0">Manage Users</small>
                                    </div>
                                    <div className="dropdown-shortcuts-item col">
                                        <span className="dropdown-shortcuts-icon rounded-circle mb-2">
                                        <i className="ti ti-lock fs-4"></i>
                                        </span>
                                        <Link to="#" className="stretched-link">Role Management</Link>
                                        <small className="text-muted mb-0">Permission</small>
                                    </div>
                                </div>
                                <div className="row row-bordered overflow-visible g-0">
                                    <div className="dropdown-shortcuts-item col">
                                        <span className="dropdown-shortcuts-icon rounded-circle mb-2">
                                        <i className="ti ti-chart-bar fs-4"></i>
                                        </span>
                                        <Link to="#" className="stretched-link">Dashboard</Link>
                                        <small className="text-muted mb-0">User Profile</small>
                                    </div>
                                    <div className="dropdown-shortcuts-item col">
                                        <span className="dropdown-shortcuts-icon rounded-circle mb-2">
                                        <i className="ti ti-settings fs-4"></i>
                                        </span>
                                        <Link to="#" className="stretched-link">Setting</Link>
                                        <small className="text-muted mb-0">Account Settings</small>
                                    </div>
                                </div>
                                <div className="row row-bordered overflow-visible g-0">
                                    <div className="dropdown-shortcuts-item col">
                                        <span className="dropdown-shortcuts-icon rounded-circle mb-2">
                                        <i className="ti ti-help fs-4"></i>
                                        </span>
                                        <Link to="#" className="stretched-link">FAQs</Link>
                                        <small className="text-muted mb-0">FAQs & Articles</small>
                                    </div>
                                    <div className="dropdown-shortcuts-item col">
                                        <span className="dropdown-shortcuts-icon rounded-circle mb-2">
                                        <i className="ti ti-square fs-4"></i>
                                        </span>
                                        <Link to="#" className="stretched-link">Modals</Link>
                                        <small className="text-muted mb-0">Useful Popups</small>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </li>
                        <li className="nav-item dropdown-notifications navbar-dropdown dropdown me-3 me-xl-1">
                            <Link className="nav-link dropdown-toggle hide-arrow" to="#;" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">
                            <i className="ti ti-bell ti-md"></i>
                            <span className="badge bg-danger rounded-pill badge-notifications">5</span>
                            </Link>
                            <ul className="dropdown-menu dropdown-menu-end py-0">
                                <li className="dropdown-menu-header border-bottom">
                                <div className="dropdown-header d-flex align-items-center py-3">
                                    <h5 className="text-body mb-0 me-auto">Notification</h5>
                                    <Link to="#" className="dropdown-notifications-all text-body" data-bs-toggle="tooltip" data-bs-placement="top" title="Mark all as read"><i className="ti ti-mail-opened fs-4"></i></Link>
                                </div>
                                </li>
                                <li className="dropdown-notifications-list scrollable-container">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item list-group-item-action dropdown-notifications-item">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar">
                                                    <img src="../../assets/img/avatars/1.png" alt="" className="h-auto rounded-circle"/>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1">Congratulation Lettie 🎉</h6>
                                                <p className="mb-0">Won the monthly best seller gold badge</p>
                                                <small className="text-muted">1h ago</small>
                                            </div>
                                            <div className="flex-shrink-0 dropdown-notifications-actions">
                                                <Link to="#" className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                <Link to="#" className="dropdown-notifications-archive"><span className="ti ti-x"></span></Link>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item list-group-item-action dropdown-notifications-item">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar">
                                                    <span className="avatar-initial rounded-circle bg-label-danger">CF</span>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1">Charles Franklin</h6>
                                                <p className="mb-0">Accepted your connection</p>
                                                <small className="text-muted">12hr ago</small>
                                            </div>
                                            <div className="flex-shrink-0 dropdown-notifications-actions">
                                                <Link to="#" className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                <Link to="#" className="dropdown-notifications-archive"><span className="ti ti-x"></span></Link>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                            <div className="avatar">
                                                <img src="../../assets/img/avatars/2.png" alt="" className="h-auto rounded-circle"/>
                                            </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1">New Message ✉️</h6>
                                                <p className="mb-0">You have new message from Natalie</p>
                                                <small className="text-muted">1h ago</small>
                                            </div>
                                            <div className="flex-shrink-0 dropdown-notifications-actions">
                                                <Link to="#" className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                <Link to="#" className="dropdown-notifications-archive"><span className="ti ti-x"></span></Link>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item list-group-item-action dropdown-notifications-item">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                            <div className="avatar">
                                                <span className="avatar-initial rounded-circle bg-label-success"><i className="ti ti-shopping-cart"></i></span>
                                            </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1">Whoo! You have new order 🛒 </h6>
                                                <p className="mb-0">ACME Inc. made new order $1,154</p>
                                                <small className="text-muted">1 day ago</small>
                                            </div>
                                            <div className="flex-shrink-0 dropdown-notifications-actions">
                                                <Link to="#" className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                <Link to="#" className="dropdown-notifications-archive"><span className="ti ti-x"></span></Link>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                            <div className="avatar">
                                                <img src="../../assets/img/avatars/9.png" alt="" className="h-auto rounded-circle"/>
                                            </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1">Application has been approved 🚀 </h6>
                                                <p className="mb-0">Your ABC project application has been approved.</p>
                                                <small className="text-muted">2 days ago</small>
                                            </div>
                                            <div className="flex-shrink-0 dropdown-notifications-actions">
                                                <Link to="#" className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                <Link to="#" className="dropdown-notifications-archive"><span className="ti ti-x"></span></Link>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                            <div className="avatar">
                                                <span className="avatar-initial rounded-circle bg-label-success"><i className="ti ti-chart-pie"></i></span>
                                            </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1">Monthly report is generated</h6>
                                                <p className="mb-0">July monthly financial report is generated </p>
                                                <small className="text-muted">3 days ago</small>
                                            </div>
                                            <div className="flex-shrink-0 dropdown-notifications-actions">
                                                <Link to="#" className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                <Link to="#" className="dropdown-notifications-archive"><span className="ti ti-x"></span></Link>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                            <div className="avatar">
                                                <img src="../../assets/img/avatars/5.png" alt="" className="h-auto rounded-circle"/>
                                            </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1">Send connection request</h6>
                                                <p className="mb-0">Peter sent you connection request</p>
                                                <small className="text-muted">4 days ago</small>
                                            </div>
                                            <div className="flex-shrink-0 dropdown-notifications-actions">
                                                <Link to="#" className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                <Link to="#" className="dropdown-notifications-archive"><span className="ti ti-x"></span></Link>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item list-group-item-action dropdown-notifications-item">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                            <div className="avatar">
                                                <img src="../../assets/img/avatars/6.png" alt="" className="h-auto rounded-circle"/>
                                            </div>
                                            </div>
                                            <div className="flex-grow-1">
                                                <h6 className="mb-1">New message from Jane</h6>
                                                <p className="mb-0">Your have new message from Jane</p>
                                                <small className="text-muted">5 days ago</small>
                                            </div>
                                            <div className="flex-shrink-0 dropdown-notifications-actions">
                                                <Link to="#" className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                <Link to="#" className="dropdown-notifications-archive"><span className="ti ti-x"></span></Link>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="list-group-item list-group-item-action dropdown-notifications-item marked-as-read">
                                        <div className="d-flex">
                                            <div className="flex-shrink-0 me-3">
                                                <div className="avatar">
                                                    <span className="avatar-initial rounded-circle bg-label-warning"><i className="ti ti-alert-triangle"></i></span>
                                                </div>
                                            </div>
                                            <div className="flex-grow-1">
                                            <h6 className="mb-1">CPU is running high</h6>
                                            <p className="mb-0">CPU Utilization Percent is currently at 88.63%,</p>
                                                <small className="text-muted">5 days ago</small>
                                            </div>
                                            <div className="flex-shrink-0 dropdown-notifications-actions">
                                                <Link to="#" className="dropdown-notifications-read"><span className="badge badge-dot"></span></Link>
                                                <Link to="#" className="dropdown-notifications-archive"><span className="ti ti-x"></span></Link>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                </li>
                                <li className="dropdown-menu-footer border-top">
                                    <Link to="#" className="dropdown-item d-flex justify-content-center text-primary p-2 h-px-40 mb-1 align-items-center">
                                    View all notifications
                                </Link>
                                </li>
                            </ul>
                        </li> */}
                        <li className="nav-item navbar-dropdown dropdown-user dropdown">
                            <Link className="nav-link dropdown-toggle hide-arrow" to="#" data-bs-toggle="dropdown">
                                <div className="avatar avatar-online">
                                <img src="../../assets/img/avatars/1.png" alt="" className="h-auto rounded-circle"/>
                                </div>
                            </Link>
                            <ul className="dropdown-menu dropdown-menu-end">
                                <li>
                                <Link className="dropdown-item" to="#" style={{textDecoration:"none"}}>
                                    <div className="d-flex">
                                        <div className="flex-shrink-0 me-3">
                                            <div className="avatar avatar-online">
                                            <img src="../../assets/img/avatars/1.png" alt="" className="h-auto rounded-circle"/>
                                            </div>
                                        </div>
                                        <div className="flex-grow-1">
                                            <span className="fw-medium d-block">{user_name}</span>
                                            <small className="text-muted">Admin</small>
                                        </div>
                                    </div>
                                </Link>
                                </li>
                                <li>
                                    <div className="dropdown-divider"></div>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="/change-password" style={{textDecoration:"none"}}>
                                        <i className="ti ti-user-check me-2 ti-sm"></i>
                                        <span className="align-middle">Change Password</span>
                                    </Link>
                                </li>
                               {/*  <li>
                                    <Link className="dropdown-item" to="#">
                                        <i className="ti ti-settings me-2 ti-sm"></i>
                                        <span className="align-middle">Settings</span>
                                    </Link>
                                </li>
                               <li>
                                    <Link className="dropdown-item" to="#">
                                        <span className="d-flex align-items-center align-middle">
                                            <i className="flex-shrink-0 ti ti-credit-card me-2 ti-sm"></i>
                                        <span className="flex-grow-1 align-middle">Billing</span>
                                        <span className="flex-shrink-0 badge badge-center rounded-pill bg-label-danger w-px-20 h-px-20">2</span>
                                        </span>
                                    </Link>
                                </li>
                                 <li>
                                    <div className="dropdown-divider"></div>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="#">
                                        <i className="ti ti-help me-2 ti-sm"></i>
                                        <span className="align-middle">FAQ</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="dropdown-item" to="#">
                                        <i className="ti ti-currency-dollar me-2 ti-sm"></i>
                                        <span className="align-middle">Pricing</span>
                                    </Link>
                                </li>
                                <li>
                                    <div className="dropdown-divider"></div>
                                </li> */}
                                <li>
                                    <button className="dropdown-item" onClickCapture={logout}>
                                        <i className="ti ti-logout me-2 ti-sm"></i>
                                        <span className="align-middle">Log Out</span>
                                    </button>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="navbar-search-wrapper search-input-wrapper  d-none">
                    <input type="text" className="form-control search-input container-xxl border-0" placeholder="Search..." aria-label="Search..."/>
                    <i className="ti ti-x ti-sm search-toggler cursor-pointer"></i>
                </div>
            </nav>
        </>
    );
}

export default Navigation;