import { Link } from "react-router-dom";
import '../../App.css';

export default function Notfound() {
    return (
        <>
            <div className="container-xxl container-p-y" style={{textAlign: "center"}}>
                <div className="misc-wrapper">
                    <h2 className="mb-1 mx-2">You are not authorized!</h2>
                    <p className="mb-4 mx-2">You do not have permission to view this page using the credentials that you have provided while login. <br/> Please contact your site administrator.</p>
                    <Link to="/login" className="btn btn-primary mb-4" style={{textDecoration:"none"}}>Back to home</Link>
                    <div className="mt-4">
                    <img src="../../assets/img/illustrations/page-misc-you-are-not-authorized.png" alt="page-misc-not-authorized" width="170" className="img-fluid"/>
                    </div>
                </div>
            </div>
        </>
    );
}    