import React, { useState, useEffect,useRef } from "react";
import Footer from "../common/Footer";
import Navigation from "../common/Navigation";
import Sidebar from "../common/Sidebar";
import CommonService from "../../services/CommonService";
import Swal from "sweetalert2";
import moment from 'moment';

const Pan = () => {
    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef(null);
    const [front, setFront] = useState(null);
    const [back, setBack] = useState(null);
    const [rcDetails, setRcDetails] = useState();
    const handleCancel = () => {
        // Reset the form
        formRef.current.reset();
        setFront(null);
        setBack(null);
        setRcDetails(null);
    }

    const rcFormSubmit = (event) =>{
        event.preventDefault();
        setIsLoading(true);
        var formData = new FormData();
            formData.append('frontImage', front);
            formData.append('backImage', back);
        CommonService.rcSearchOCR(formData)
        .then((response) => {
            if(response.data.status === 200){
                setRcDetails(response.data.data);
                setIsLoading(false);
            }else{
                
                Swal.fire({
                    icon: 'error',
                    title: response.data.status,
                    text: response.data.message,
                })
                setIsLoading(false);
                setRcDetails(null);
            }
        })
        .catch((error) => {
            console.log('An error occurred:', error.response.data.data);
            if(error.response.data.status === 510){
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.status,
                    text: error.response.data.data,
                })
                setIsLoading(false);
                setRcDetails(null);
            }else{
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.status,
                    text: error.response.data.message,
                })
                setIsLoading(false);
                setRcDetails(null);
            }
           
         
        });
    }

    return (
        <>
        <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Sidebar/>
                <div className="layout-page">
                    <Navigation/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card h-100">
                                        <div className="card-body"> 
                                            <div className="row">
                                                <h5 className="card-header">RC OCR Report</h5>
                                                <hr/>
                                            </div>
                                            {isLoading ? (
                                                <div className="d-flex justify-content-center">
                                                    <div className="sk-fold sk-primary">
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                        <div className="sk-fold-cube"></div>
                                                    </div>
                                                </div>
                                            ) : ( 
                                            <form className="card-body" ref={formRef} style={{paddingTop:"0px", paddingLeft:"0px"}} data-parsley-validate="" onSubmit={ rcFormSubmit }>
                                                <div className="row">    
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="multicol-username">Upload RC Front Image</label>
                                                        <input type="file" onChange={(e) => setFront(e.target.files[0])} required data-parsley-required="true" className="form-control" />
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className="form-label" htmlFor="multicol-username">Upload RC Back Image</label>
                                                        <input type="file" onChange={(e) => setBack(e.target.files[0])} required data-parsley-required="true" className="form-control" />
                                                    </div>
                                                </div>
                                               <div className="pt-4">
                                                    <button type="submit" className="btn btn-primary me-sm-3 me-1">Find Status</button>
                                                    <button type="button" className="btn btn-label-secondary" onClick={handleCancel}>Reset</button>
                                                </div>
                                            </form> 
                                            )} 
                                            
                                            { rcDetails ? (
                                                <>
                                                <div className="row">
                                                    <div className="col-md-7">
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4" style={{marginBottom:'0px !important'}}>
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='menu-icon tf-icons ti ti-truck me-1'></i> RC DETAILS
                                                            </p>
                                                        </ul>
                                                        <div className="card-body" style={{paddingTop:"0px"}}>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                   <b>RC Registration Number</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.rc_Number ? rcDetails.data.rc_Number : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <b>RC Registration Date</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.regn_Date ? rcDetails.data.regn_Date : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>Registered Validity</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.regn_Validity ? rcDetails.data.regn_Validity : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <b>RC Valid From</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.valid_From ? rcDetails.data.valid_From : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                   <b>RC Valid Upto</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.valid_Upto ? rcDetails.data.valid_Upto : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <b>RC Status as on</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                    {moment().format('YYYY-MM-DD')}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>RTO Code</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.rc_Number.slice(0, 2)+'-'+rcDetails.data.rc_Number.slice(2, 4)}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <b>Tax Paid Upto</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.tax_Up ? rcDetails.data.tax_Up : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>Purpose</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.purpose ? rcDetails.data.purpose : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <b>Hypothecated To</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.hypothecated_To ? rcDetails.data.hypothecated_To : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>Issuing Authority</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.issuing_Authority ? rcDetails.data.issuing_Authority : 'NA'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='menu-icon tf-icons ti ti-truck me-1'></i> FINANCE & INSURANCE DETAILS
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Financier</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.financier_Name ? rcDetails.data.financier_Name : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Financed</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.insurance_Provider ? rcDetails.data.insurance_Provider : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Insurance Company</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.insurance_Provider ? rcDetails.data.insurance_Provider : 'NA'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='menu-icon tf-icons ti ti-truck me-1'></i> VEHICLE OWNER DETAILS
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>RC Owner's Name</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.owner_Name ? rcDetails.data.owner_Name : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Owner Father Name</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.sdw_Of ? rcDetails.data.sdw_Of : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>DOB</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.dob ? rcDetails.data.dob : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Number's of Owner</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                {rcDetails.data.owner_Serial ? rcDetails.data.owner_Serial : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Present Address</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.present_Address ? rcDetails.data.present_Address : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Permanent Address</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                {rcDetails.data.permanent_Address ? rcDetails.data.permanent_Address : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>State</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                {rcDetails.data.state ? rcDetails.data.state : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>City</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                {rcDetails.data.city ? rcDetails.data.city : 'NA'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="col-md-5">
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='ti-xs ti ti-link me-1'></i> VEHICLE DETAILS
                                                            </p>
                                                        </ul>
                                                        <div className="card-body" style={{paddingTop:"0px"}}>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                   <b>Vehicle Class</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.class ? rcDetails.data.class : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                   <b>Wheel Base</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.wheel_Base ? rcDetails.data.wheel_Base : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                   <b>Manufacture Name</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.manufacturer_Name ? rcDetails.data.manufacturer_Name : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                   <b>Manufacture Date</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.month_Year_Manufacturer ? rcDetails.data.month_Year_Manufacturer : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                   <b>Horse Power</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.horse_Power ? rcDetails.data.horse_Power : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <b>Fuel Type</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.fuel ? rcDetails.data.fuel : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>Chassis Number</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.chassis_No ? rcDetails.data.chassis_No : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <b>Model Number</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.model_No ? rcDetails.data.model_No : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                   <b>Engine Number</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.engine_No ? rcDetails.data.engine_No : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <b>Maker Class</b>
                                                                </div>
                                                                <div className="col-md-6" style={{ textAlign: "right" }}>
                                                                    {rcDetails.data.makers_Class ? rcDetails.data.makers_Class : 'NA'}
                                                                </div>
                                                            </div>
                                                           
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>Color</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.vehicle_Colour ? rcDetails.data.vehicle_Colour : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <b>Registered Laden Weight</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.rlw ? rcDetails.data.rlw : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>Unladen Weight</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.unladen_Weight ? rcDetails.data.unladen_Weight : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <b>Vehicle Weight</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.vehicle_Weight ? rcDetails.data.vehicle_Weight : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>Body Type</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.body_Type ? rcDetails.data.body_Type : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <b>No. of Cylinders</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.no_Ofcylinders ? rcDetails.data.no_Ofcylinders : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>Seating Capacity</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.seat_Capacity ? rcDetails.data.seat_Capacity : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <b>Cubic Capacity</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.cubic_Capacity ? rcDetails.data.cubic_Capacity : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>Standing Capacity</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.stand_Capacity ? rcDetails.data.stand_Capacity : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-6" >
                                                                    <b>Standing Capacity</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.standing_capacity ? rcDetails.data.standing_capacity : 'NA'}
                                                                </div>
                                                            </div>
                                                            <div className="row" style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6" >
                                                                    <b>Emission norms</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.emission_Norms ? rcDetails.data.emission_Norms : 'NA'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                                <i className='menu-icon tf-icons ti ti-truck me-1'></i> RC STATUS
                                                            </p>
                                                        </ul>
                                                        <div className="card-body" style={{paddingTop:"0px"}}>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>RC Status</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.data.rc_Status ? rcDetails.data.rc_Status : 'NA'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ul className="nav nav-pills flex-column flex-sm-row mb-4">
                                                            <p className="nav-link active" style={{fontSize:"19px"}}>
                                                            <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-clock-12"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M3 12a9 9 0 0 0 9 9m9 -9a9 9 0 1 0 -18 0" /><path d="M12 7v5l.5 .5" /><path d="M18 15h2a1 1 0 0 1 1 1v1a1 1 0 0 1 -1 1h-1a1 1 0 0 0 -1 1v1a1 1 0 0 0 1 1h2" /><path d="M15 21v-6" /></svg> &nbsp;  API HEALTH CHECK
                                                            </p>
                                                        </ul>
                                                        <div className="card-body"  style={{paddingTop:"0px"}}>
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b> Request Time</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.requestTimestamp}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <b>Response Time</b>
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.responseTimestamp}
                                                                </div>
                                                            </div>
                                                            
                                                            <div className="row"  style={{background: '#ebe9e9'}}>
                                                                <div className="col-md-6">
                                                                    <b>Latency(sec)</b> 
                                                                </div>
                                                                <div className="col-md-6" style={{textAlign:"right"}}>
                                                                    {rcDetails.totalTime}
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                             </>
                                            ) : ( '' )}   
                                            
                                        </div>
                                    </div>    
                                </div>
                             </div> 
                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
            <div className="drag-target"></div>
        </div>    
    </>
    )
}   

export default Pan;