import React, { useEffect, useContext, useState } from 'react';
import { useLocation, Navigate, Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import CommonService from "../../services/CommonService";
import Swal from "sweetalert2";



const Resetpassword = () => {
    const nav = useNavigate();
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [isVisible, setVisible] = useState(false);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const email = searchParams.get('email');
    console.log(email);
    useEffect(() => {
        if (!token || !email) {
            // Redirect to login page if token or email is missing
            // You can customize the login route as needed
            window.location.href = '/login';
        }
    }, [token, email]);

    const resetPasswordFormSubmit = (event) =>{
        event.preventDefault(); // Prevent default form submission behavior
    
        setIsLoading(true);
        if (newPassword !== confirmPassword) {
            Swal.fire({
                icon: 'error',
                title: 'Password mismatch',
                text: 'Password and confirm password not matched',
            })
            setIsLoading(false);
            return;
        }

        const lengthRegex = /^.{8,16}$/;
        const uppercaseRegex = /^(?=.*[A-Z]).*$/;
        const numericRegex = /^(?=.*\d).*$/;
        const specialCharRegex = /^(?=.*[!@#$%^&*()_+{}\[\]:;<>,.\/?]).*$/;
    
        if (
            !lengthRegex.test(newPassword) ||
            !uppercaseRegex.test(newPassword) ||
            !numericRegex.test(newPassword) ||
            !specialCharRegex.test(newPassword)
        ) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid Password',
                text: 'Password must meet the specified conditions.',
            });
            setIsLoading(false);
            return;
        }
    
        var data = {
            "id": email,
            "newPassword": newPassword,
        };
    
        CommonService.resetChnage(data)
            .then((response) => {
            if (response.data.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: response.data.status,
                    text: response.data.message,
                }).then((result) => {
                    if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                        nav("/login");
                    }
                });
                setIsLoading(false);
            } else {

                Swal.fire({
                    icon: 'error',
                    title: response.data.status,
                    text: response.data.message,
                })
                setIsLoading(false);
            }
        })
        .catch((error) => {
            console.log('An error occurred:', error);
            Swal.fire({
                icon: 'error',
                title: error.response.data.status,
                text: error.response.data.message,
            })
            setIsLoading(false);
        }); 
    }

    const toggle = () => {
        setVisible(!isVisible);
    };

    const toggleConfirm = () => {
        setConfirmVisible(!confirmVisible);
    };
    return (
        <>
        <style>
           {` 
                #parsley-id-5 {
                    margin: 41px 0px;
                }
          `}
        </style>
           <div className="authentication-wrapper authentication-cover authentication-bg ">
                <div className="authentication-inner row">
                    <div className="d-none d-lg-flex col-lg-7 p-0">
                        <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
                            <img src="../../assets/img/illustrations/auth-reset-password-illustration-light.png" alt="auth-reset-password-cover" className="img-fluid my-5 auth-illustration" data-app-light-img="illustrations/auth-reset-password-illustration-light.png" data-app-dark-img="illustrations/auth-reset-password-illustration-dark.png"/>

                            <img src="../../assets/img/illustrations/bg-shape-image-light.png" alt="auth-reset-password-cover" className="platform-bg" data-app-light-img="illustrations/bg-shape-image-light.png" data-app-dark-img="illustrations/bg-shape-image-dark.png"/>
                        </div>
                    </div>
                    <div className="d-flex col-12 col-lg-5 align-items-center p-4 p-sm-5">
                        <div className="w-px-400 mx-auto">
                            <div className="app-brand mb-4">
                                <Link  className="app-brand-link gap-2">
                                    <span className="app-brand-logo demo">
                                    <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z" fill="#7367F0"></path>
                                        <path opacity="0.06" fillRule="evenodd" clipRule="evenodd" d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z" fill="#161616"></path>
                                        <path opacity="0.06" fillRule="evenodd" clipRule="evenodd" d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z" fill="#161616"></path>
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z" fill="#7367F0"></path>
                                    </svg>
                                    </span>
                                </Link>
                            </div>
                            <h4 className="mb-1">Reset Password 🔒</h4>
                            <p className="mb-4">for <span className="fw-medium">example@email.com</span></p>
                            <h6>Note:-</h6>
                            <p>1.Password length should be minimum 8 to 16.</p>
                            <p>2.First letter should be upper case.</p>
                            <p>3.One numeric value at least.</p>
                            <p>4.One special character at least.</p>
                           
                            {isLoading ? (
                                <div className="d-flex justify-content-center">
                                    <div className="sk-fold sk-primary">
                                        <div className="sk-fold-cube"></div>
                                        <div className="sk-fold-cube"></div>
                                        <div className="sk-fold-cube"></div>
                                        <div className="sk-fold-cube"></div>
                                    </div>
                                </div>
                            ) : ( 
                            <form id="formAuthentication" className="mb-3" data-parsley-validate=""  onSubmit={ resetPasswordFormSubmit }>
                                <div style={{marginBottom:" 2rem"}}><br/>
                                    <label className="form-label" htmlFor="multicol-username">New Password</label>
                                    <div className="input-group input-group-merge">
                                        <input type={!isVisible ? "password" : "text"} 
                                        id="password" className="form-control" 
                                        name="password" placeholder="Please enter password!" aria-describedby="password" required data-parsley-required="true"
                                        defaultValue={newPassword}
                                        onChange={(e) => { setNewPassword(e.target.value) }}
                                        />
                                        <span className="input-group-text cursor-pointer" onClick={toggle}>
                                            {isVisible ? <i className="ti ti-eye"></i>: <i className="ti ti-eye-off"></i> }
                                        </span>
                                    </div>
                                </div>
                                <div style={{marginBottom:" 2rem"}}>
                                    <label className="form-label" htmlFor="multicol-username">Confirm Password</label>
                                    <div className="input-group input-group-merge">
                                        <input type={!confirmVisible ? "password" : "text"} id="password" className="form-control" name="password" placeholder="Please enter confirm password!" aria-describedby="password" required data-parsley-required="true"
                                        defaultValue={confirmPassword}
                                        onChange={(e) => { setConfirmPassword(e.target.value) }}
                                        />
                                        <span className="input-group-text cursor-pointer" onClick={toggleConfirm}>
                                            {confirmVisible ? <i className="ti ti-eye"></i>: <i className="ti ti-eye-off"></i> }
                                        </span>
                                    </div>    
                                </div>
                                <button className="btn btn-primary d-grid w-100 mb-3">
                                    Set new password
                                </button>
                                <div className="text-center">
                                    <a href="/login">
                                    <i className="ti ti-chevron-left scaleX-n1-rtl"></i>
                                    Back to login
                                    </a>
                                </div>
                            </form>
                            )} 
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}   

export default Resetpassword;