import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { DateRangePicker } from 'rsuite';
import { FaCalendar, FaClock } from 'react-icons/fa';
import Select from 'react-select'
import Footer from "./common/Footer";
import Sidebar from "./common/Sidebar";
import Navigation from "./common/Navigation";
import CommonService from "../services/CommonService";

export default function Dashboard() {
  var moment = require('moment');
  const date = moment().format('MMMM Do YYYY');
  const [clientData, setClientData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentTime, setCurrentTime] = useState(moment().format('h:mm:ss A'));
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [selectedTimeRange, setSelectedTimeRange] = useState([
    moment().startOf('day').toDate(), // Initialize with current time
    new Date(), // Initialize with current time
  ]);
  const [isAllChecked, setIsAllChecked] = useState(true);
  const [isUniqueChecked, setIsUniqueChecked] = useState(true);
  const [isDateRangeChecked, setIsDateRangeChecked] = useState(true);
  const [dashbordlistdata, setDashbordlistdata] = useState([]);

  const getDashboardDetails = () => {
    const id = localStorage.getItem('id');
    let formattedDateRange = "";
    if (selectedDateRange.length === 0) {
        formattedDateRange = `${moment().format("DD/MM/YYYY")}-${moment().format("DD/MM/YYYY")}`;
    } else {
        formattedDateRange = `${moment(selectedDateRange[0]).format("DD/MM/YYYY")}-${moment(selectedDateRange[1]).format("DD/MM/YYYY")}`;
    }
   
    const payload = {
        clientId: id,
        dateRange: formattedDateRange,
        fromTime: moment(selectedTimeRange[0]).format("HH:mm:ss"), 
        toTime: moment(selectedTimeRange[1]).format("HH:mm:ss"), 
        unique: isUniqueChecked,
        timeCheck: isDateRangeChecked,
    };
  
    CommonService.getDashboard(payload)
      .then((response) => {
        const dashboardListData = response.data.data;
        setDashbordlistdata(dashboardListData);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("Error:", error);
        setIsLoading(false);
      });
};

  useEffect(() => {
    getDashboardDetails();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(moment().format('h:mm:ss A'));
    }, 1000);

    return () => clearInterval(intervalId);
    
  }, [selectedDateRange]);

  const handleAllCheckboxChange = (event) => {
    setIsAllChecked(event.target.checked);
  }

  const handleUniqueCheckboxChange = (event) => {
    setIsUniqueChecked(event.target.checked);
  }

  const handleDateRangeCheckboxChange = (event) => {
    setIsDateRangeChecked(event.target.checked);
  }

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "38px",
    }),
    menu: (provided, state) => ({
      ...provided,
      maxHeight: "150px", 
      overflowY: "auto",
    }),
  };

    return (
        <>
        {isLoading ? (
            <div className="d-flex justify-content-center">
                <div className="sk-fold sk-primary">
                    <div className="sk-fold-cube"></div>
                    <div className="sk-fold-cube"></div>
                    <div className="sk-fold-cube"></div>
                    <div className="sk-fold-cube"></div>
                </div>
            </div>
        ) : (
            <div className="layout-wrapper layout-content-navbar">
                <div className="layout-container">
                    <Sidebar/>
                    <div className="layout-page">
                        <Navigation/>
                        <div className="content-wrapper">
                            <div className="container-xxl flex-grow-1 container-p-y">
                                <div className="row">
                                    <div className="col-md-8">
                                        <div className="card h-100">
                                            <div className="card-body"> 
                                                <div className="row">
                                                    <div className="row">
                                                        <div className="col-md-7"></div>
                                                        <div className="col-md-5" style={{ textAlign: "right" }}>
                                                            <label className="form-check-label" htmlFor="customCheckPrimary">
                                                                <b>{date} </b><b>{currentTime}</b>
                                                            </label>
                                                        </div><br />
                                                    </div>
                                                    <div className="col-md-3">
                                                        <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        value=""
                                                        id="uniqueCheckbox"
                                                        checked={isUniqueChecked}
                                                        onChange={handleUniqueCheckboxChange}
                                                        style={{ width: "2em", height: "2em", marginTop: "27px" }}
                                                        />
                                                        <label className="form-check-label" htmlFor="uniqueCheckbox" style={{ marginTop: "30px", paddingLeft: "10px" }}>Unique</label>
                                                    </div>
                                                    </div><br />
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <label className="form-label" htmlFor="multicol-username">Date Range</label>
                                                            <DateRangePicker
                                                                    format="dd/MM/yyyy"
                                                                    character=" – "
                                                                    onChange={(value) => setSelectedDateRange(value)}
                                                                />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value=""
                                                                id="dateRangeCheckbox"
                                                                checked={isDateRangeChecked}
                                                                onChange={handleDateRangeCheckboxChange}
                                                            />
                                                            <label className="form-label" htmlFor="multicol-username" style={{ marginBottom: "2px", paddingLeft: "8px" }}>Time Range</label>
                                                            <DateRangePicker
                                                                placeholder="Select Time Range"
                                                                disabled={isDateRangeChecked}
                                                                format="HH:mm:ss"
                                                                caretAs={FaClock}
                                                                onChange={(value) => setSelectedTimeRange([value[0], value[1]])} // Update selected time range
                                                                value={selectedTimeRange}
                                                            />
                                                        </div>
                                                    </div> <br />
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <button type="button" className="btn btn-outline-success waves-effect"  onClick={getDashboardDetails}>
                                                                <span className="ti-xs ti ti-search me-1"></span>Search
                                                            </button>
                                                        </div>  
                                                </div>      
                                            </div>
                                        </div>    
                                    </div>    
                                    <div className="col-md-4">
                                        <div className="card h-100">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6"></div>
                                                    <div className="col-md-6">
                                                        <div className="card-title mb-0">
                                                            <h5 className="mb-0">TOTAL SEARCH<br/><br/></h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6"></div>
                                                    <div className="col-md-6">
                                                        <div className="d-flex gap-2 align-items-center mb-2 pb-1 flex-wrap">
                                                            <h1 className="mb-0">{dashbordlistdata?.reporttotal?.total}</h1>
                                                            <div className="badge rounded bg-label-primary">TOTAL</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <div className="badge rounded bg-label-success p-1"><i className="ti ti-square-check ti-sm"></i></div>
                                                            <h6 className="mb-0">SUCCESS</h6>
                                                        </div>
                                                        <h4 className="my-2 pt-1">{dashbordlistdata?.reporttotal?.success}({dashbordlistdata?.reporttotal?.success_percent})%</h4>
                                                        <div className="progress w-75" style={{height:"4px"}}>
                                                            <div className="progress-bar" role="progressbar" style={{ width: `${dashbordlistdata?.reporttotal?.success_percent}%` }} aria-valuenow={dashbordlistdata?.reporttotal?.success_percent} aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="d-flex gap-2 align-items-center">
                                                            <div className="badge rounded bg-label-danger p-1"><i className="ti ti-square-rounded-x ti-sm"></i></div>
                                                            <h6 className="mb-0">FAIL</h6>
                                                        </div>
                                                        <h4 className="my-2 pt-1">{dashbordlistdata?.reporttotal?.fail}({dashbordlistdata?.reporttotal?.fail_percent})%</h4>
                                                        <div className="progress w-75" style={{height:"4px"}}>
                                                            <div className="progress-bar bg-danger" role="progressbar" style={{width: `${dashbordlistdata?.reporttotal?.fail_percent}%`}} aria-valuenow={dashbordlistdata?.reporttotal?.fail_percent} aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                 </div> <br/>
                                 <div className="row">
                                 {
                                    dashbordlistdata && dashbordlistdata.report && dashbordlistdata.report.map((valData) => (
                                        <div className="col-lg-3 col-sm-6 mb-4">
                                            <div className="card"  style={{ marginBottom: "20px !important" }}>
                                            <div className="card-header">
                                                <div className="d-flex justify-content-between">
                                                    <div className="avatar me-2">
                                                        <span className="avatar-initial rounded bg-label-primary"><i className={valData.product_icon}></i></span>
                                                    </div>
                                                    <p className="card-text" style={{ fontWeight: "600", fontSize: "17px", color: "#7367f0" }}>{valData.name}</p>
                                                </div>
                                                <h4 className="card-title mb-1">{valData.total}</h4>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                    <div className="col-4">
                                                        <div className="d-flex gap-2 align-items-center mb-2">
                                                        <span className="badge bg-label-info p-1 rounded text-success"><i className="ti ti-square-check" style={{fontSize:"1.75rem"}}></i></span>
                                                        <p className="mb-0">Success</p>
                                                        </div>
                                                        <h5 className="mb-0 pt-1 text-nowrap">{valData.success_percent}%</h5>
                                                        <small className="text-muted">{valData.success}</small>
                                                    </div>
                                                    <div className="col-4">
                                                        <div className="divider divider-vertical">
                                                        <div className="divider-text">
                                                            <span className="badge-divider-bg bg-label-secondary">VS</span>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 text-end">
                                                        <div className="d-flex gap-2 justify-content-end align-items-center mb-2">
                                                        <p className="mb-0">Faild</p>
                                                        <span className="badge bg-label-danger p-1 rounded"><i className="ti ti-square-x" style={{fontSize:"1.75rem"}}></i></span>
                                                        </div>
                                                        <h5 className="mb-0 pt-1 text-nowrap ms-lg-n3 ms-xl-0">{valData.fail_percent}%</h5>
                                                        <small className="text-muted">{valData.fail}</small>
                                                    </div>
                                                    </div>
                                                    <div className="d-flex align-items-center mt-4">
                                                    <div className="progress w-100" style={{height: "8px"}}>
                                                        <div className="progress-bar bg-success" style={{width: `${valData.success_percent}%`}} role="progressbar" aria-valuenow={valData.success_percent} aria-valuemin="0" aria-valuemax="100"></div>
                                                        <div className="progress-bar bg-danger" role="progressbar" style={{width: `${valData.fail_percent}%`}} aria-valuenow={valData.fail_percent} aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }

                                 </div>
                            </div>
                            <Footer/>
                            <div className="content-backdrop fade"></div>
                        </div>
                    </div>
                </div>
                <div className="layout-overlay layout-menu-toggle"></div>
                <div className="drag-target"></div>
            </div>  
         )}      
        </>
    );
     

}
