// axiosInstance.js

import axios from 'axios';
import setting from './setting';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const createAxiosInstance = async () => {
  const authToken = await getToken();
  const axiosInstance = axios.create({
    baseURL: setting.APP_DEV_URL,
    headers: {
      'Content-type': 'application/json',
      'Authorization': `Bearer ${authToken}`
    }
  });

  return axiosInstance;
};

const createAxiosInstanceImage = async () => {
  const authToken = await getToken();
  const axiosInstance = axios.create({
    baseURL: setting.APP_DEV_URL,
    headers: {
      'Content-type': 'application/json',
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${authToken}`
    }
  });

  return axiosInstance;
};

const getToken = async () => {
  try {
    return await cookies.get('auth_token');
  } catch (e) {
    console.error('Error getting access token: ' + e);
  }
};

export { createAxiosInstance, createAxiosInstanceImage };
