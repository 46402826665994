import React, { useState, useRef } from "react";
import { Link, useNavigate } from 'react-router-dom'
import CommonService from "../../services/CommonService";
import Swal from "sweetalert2";
import 'parsleyjs';
import '../../App.css';


const Forgotpassword = () => {
    const nav = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [email, setEmail] = useState('');
   

    const forgotPassword = (event) => {
        event.preventDefault(); // Prevent default form submission behavior
    
        setIsLoading(true);
       
        var data = {
            "email": email,
        };
    
        CommonService.forgotPassword(data)
            .then((response) => {
            if (response.data.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: response.data.status,
                    text: response.data.message,
                }).then((result) => {
                    if (result.isConfirmed || result.dismiss === Swal.DismissReason.timer) {
                        nav("/dashboard");
                    }
                });
                setIsLoading(false);
            } else {

                Swal.fire({
                    icon: 'error',
                    title: response.data.status,
                    text: response.data.message,
                })
                setIsLoading(false);
            }
        })
        .catch((error) => {
            console.log('An error occurred:', error);
            Swal.fire({
                icon: 'error',
                title: error.response.data.status,
                text: error.response.data.message,
            })
            setIsLoading(false);
        });       
    }
    

    return (
        <>
        <div className="authentication-wrapper authentication-cover authentication-bg">
            <div className="authentication-inner row">
                <div className="d-none d-lg-flex col-lg-7 p-0">
                <div className="auth-cover-bg auth-cover-bg-color d-flex justify-content-center align-items-center">
                    <img src="../../assets/img/illustrations/auth-forgot-password-illustration-light.png" alt="auth-forgot-password-cover" className="img-fluid my-5 auth-illustration" data-app-light-img="illustrations/auth-forgot-password-illustration-light.png" data-app-dark-img="illustrations/auth-forgot-password-illustration-dark.png"/>

                    <img src="../../assets/img/illustrations/bg-shape-image-light.png" alt="auth-forgot-password-cover" className="platform-bg" data-app-light-img="illustrations/bg-shape-image-light.png" data-app-dark-img="illustrations/bg-shape-image-dark.png"/>
                </div>
                </div>
                <div className="d-flex col-12 col-lg-5 align-items-center p-sm-5 p-4">
                    <div className="w-px-400 mx-auto">
                        <div className="app-brand mb-4">
                            <Link className="app-brand-link gap-2">
                                <img src={process.env.PUBLIC_URL + '/logo.jpg'} style={{width:'100px'}}/>
                            </Link>
                        </div>
                        <h3 className="mb-1">Forgot Password? 🔒</h3>
                        <p className="mb-4">Enter your email and we'll send you instructions to reset your password</p>
                        <form id="formAuthentication" className="mb-3" data-parsley-validate="" onSubmit={forgotPassword}  method="GET">
                            <div style={{ marginBottom:" 2rem" }}>
                                <label htmlFor="email" className="form-label">Email</label>
                                <input type="text" className="form-control" id="email" required data-parsley-required="true" name="email" placeholder="Enter your email"
                                defaultValue={email}
                                onChange={(e) => { setEmail(e.target.value) }}/>
                            </div>
                            <button type="submit" className="btn btn-primary d-grid w-100" >Send Reset Link</button>
                        </form>
                        <div className="text-center">
                            <Link to="/login" className="d-flex align-items-center justify-content-center" style={{ textDecoration: "none" }}>
                                <i className="ti ti-chevron-left scaleX-n1-rtl"></i>
                                Back to login
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>    
    </>
    )
}   

export default Forgotpassword;